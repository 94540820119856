import { connectRouter } from 'connected-react-router';
import layout from 'src/modules/layout/layoutReducers';
import auth from 'src/modules/auth/authReducers';
import tenant from 'src/modules/tenant/tenantReducers';
import plan from 'src/modules/plan/planReducers';
import user from 'src/modules/user/userReducers';
import auditLog from 'src/modules/auditLog/auditLogReducers';
import programLog from 'src/modules/programLog/programLogReducers';
import settings from 'src/modules/settings/settingsReducers';
import userData from 'src/modules/userData/userDataReducers';
import dailyReport from 'src/modules/dailyReport/dailyReportReducers';
import monthlyReports from 'src/modules/monthlyReports/monthlyReportsReducers';
import ads from 'src/modules/ads/adsReducers';
import { combineReducers } from 'redux';

export default (history) =>
  combineReducers({
    router: connectRouter(history),
    layout,
    auth,
    tenant,
    plan,
    user,
    auditLog,
    programLog,
    settings,
    userData,
    dailyReport,
    monthlyReports,
    ads,
  });

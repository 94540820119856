import schemas from 'src/modules/shared/yup/yupImporterSchemas';
import { i18n } from 'src/i18n';import moment from 'moment';

export default [
  {
    name: 'programName',
    label: i18n('entities.ads.fields.programName'),
    schema: schemas.string(
      i18n('entities.ads.fields.programName'),
      {},
    ),
  },
  {
    name: 'startDate',
    label: i18n('entities.ads.fields.startDate'),
    schema: schemas.date(
      i18n('entities.ads.fields.startDate'),
      {},
    ),
   render: (value) => value && value instanceof Date ? moment(value).format('YYYY-MM-DD') : value,
  },
  {
    name: 'endDate',
    label: i18n('entities.ads.fields.endDate'),
    schema: schemas.datetime(
      i18n('entities.ads.fields.endDate'),
      {},
    ),
   render: (value) => value && value instanceof Date ? moment(value).format('YYYY-MM-DD HH:mm') : value,
  },
  {
    name: 'businessId',
    label: i18n('entities.ads.fields.businessId'),
    schema: schemas.string(
      i18n('entities.ads.fields.businessId'),
      {},
    ),
  },
  {
    name: 'jobId',
    label: i18n('entities.ads.fields.jobId'),
    schema: schemas.string(
      i18n('entities.ads.fields.jobId'),
      {},
    ),
  },
  {
    name: 'budget',
    label: i18n('entities.ads.fields.budget'),
    schema: schemas.integer(
      i18n('entities.ads.fields.budget'),
      {},
    ),
  },
  {
    name: 'currencyCode',
    label: i18n('entities.ads.fields.currencyCode'),
    schema: schemas.string(
      i18n('entities.ads.fields.currencyCode'),
      {},
    ),
  },
  {
    name: 'isAutobid',
    label: i18n('entities.ads.fields.isAutobid'),
    schema: schemas.boolean(
      i18n('entities.ads.fields.isAutobid'),
      {},
    ),
  },
  {
    name: 'maxBid',
    label: i18n('entities.ads.fields.maxBid'),
    schema: schemas.integer(
      i18n('entities.ads.fields.maxBid'),
      {},
    ),
  },
  {
    name: 'pacingMethod',
    label: i18n('entities.ads.fields.pacingMethod'),
    schema: schemas.string(
      i18n('entities.ads.fields.pacingMethod'),
      {},
    ),
  },
  {
    name: 'feePeriod',
    label: i18n('entities.ads.fields.feePeriod'),
    schema: schemas.string(
      i18n('entities.ads.fields.feePeriod'),
      {},
    ),
  },
  {
    name: 'adCategories',
    label: i18n('entities.ads.fields.adCategories'),
    schema: schemas.string(
      i18n('entities.ads.fields.adCategories'),
      {},
    ),
  },
  {
    name: 'futureBudgetDate',
    label: i18n('entities.ads.fields.futureBudgetDate'),
    schema: schemas.date(
      i18n('entities.ads.fields.futureBudgetDate'),
      {},
    ),
   render: (value) => value && value instanceof Date ? moment(value).format('YYYY-MM-DD') : value,
  },
  {
    name: 'programId',
    label: i18n('entities.ads.fields.programId'),
    schema: schemas.string(
      i18n('entities.ads.fields.programId'),
      {},
    ),
  },
];
import DailyReportService from 'src/modules/dailyReport/dailyReportService';
import selectors from 'src/modules/dailyReport/list/dailyReportListSelectors';
import { createAsyncThunk } from '@reduxjs/toolkit';
import tenantSelectors from '../../tenant/list/tenantListSelectors';
import { i18n } from 'src/i18n';
import exporterFields from 'src/modules/dailyReport/list/dailyReportListExporterFields';
import Errors from 'src/modules/shared/error/errors';
import Exporter from 'src/modules/shared/exporter/exporter';

const prefix = 'DAILYREPORT_LIST';

const dailyReportListActions = {
  FETCH_STARTED: `${prefix}_FETCH_STARTED`,
  FETCH_SUCCESS: `${prefix}_FETCH_SUCCESS`,
  FETCH_ERROR: `${prefix}_FETCH_ERROR`,

  FETCH_MAX_BID_TABLE_DATA_STARTED: `${prefix}_FETCH_STARTED`,
  FETCH_MAX_BID_TABLE_DATA_SUCCESS: `${prefix}_FETCH_SUCCESS`,
  FETCH_MAX_BID_TABLE_DATA_ERROR: `${prefix}_FETCH_ERROR`,

  RESETED: `${prefix}_RESETED`,
  TOGGLE_ONE_SELECTED: `${prefix}_TOGGLE_ONE_SELECTED`,
  TOGGLE_ALL_SELECTED: `${prefix}_TOGGLE_ALL_SELECTED`,
  CLEAR_ALL_SELECTED: `${prefix}_CLEAR_ALL_SELECTED`,

  PAGINATION_CHANGED: `${prefix}_PAGINATION_CHANGED`,
  SORTER_CHANGED: `${prefix}_SORTER_CHANGED`,

  EXPORT_STARTED: `${prefix}_EXPORT_STARTED`,
  EXPORT_SUCCESS: `${prefix}_EXPORT_SUCCESS`,
  EXPORT_ERROR: `${prefix}_EXPORT_ERROR`,

  doClearAllSelected() {
    return {
      type: dailyReportListActions.CLEAR_ALL_SELECTED,
    };
  },

  doToggleAllSelected() {
    return {
      type: dailyReportListActions.TOGGLE_ALL_SELECTED,
    };
  },

  doToggleOneSelected(id) {
    return {
      type: dailyReportListActions.TOGGLE_ONE_SELECTED,
      payload: id,
    };
  },

  doReset: () => async (dispatch) => {
    dispatch({
      type: dailyReportListActions.RESETED,
    });

    dispatch(dailyReportListActions.doFetch());
  },

  doExport: () => async (dispatch, getState) => {
    try {
      if (!exporterFields || !exporterFields.length) {
        throw new Error('exporterFields is required');
      }

      dispatch({
        type: dailyReportListActions.EXPORT_STARTED,
      });

      const filter = selectors.selectFilter(getState());
      const response = await DailyReportService.list(
        filter,
        selectors.selectOrderBy(getState()),
        null,
        null,
      );

      new Exporter(
        exporterFields,
        i18n('entities.dailyReport.exporterFileName'),
      ).transformAndExportAsExcelFile(response.rows);

      dispatch({
        type: dailyReportListActions.EXPORT_SUCCESS,
      });
    } catch (error) {
      Errors.handle(error);

      dispatch({
        type: dailyReportListActions.EXPORT_ERROR,
      });
    }
  },

  doChangePagination: (pagination) => async (
    dispatch,
    getState,
  ) => {
    dispatch({
      type: dailyReportListActions.PAGINATION_CHANGED,
      payload: pagination,
    });

    dispatch(dailyReportListActions.doFetchCurrentFilter());
  },

  doChangeSort: (sorter) => async (dispatch, getState) => {
    dispatch({
      type: dailyReportListActions.SORTER_CHANGED,
      payload: sorter,
    });

    dispatch(dailyReportListActions.doFetchCurrentFilter());
  },

  doFetchCurrentFilter: () => async (
    dispatch,
    getState,
  ) => {
    const filter = selectors.selectFilter(getState());
    const rawFilter = selectors.selectRawFilter(getState());
    dispatch(dailyReportListActions.doFetch(filter, rawFilter, true));
  },

  doFetch: (filter?, rawFilter?, keepPagination = false) => async (
    dispatch,
    getState,
  ) => {
    try {
      dispatch({
        type: dailyReportListActions.FETCH_STARTED,
        payload: { filter, rawFilter, keepPagination },
      });

      const response = await DailyReportService.list(
        filter,
        selectors.selectOrderBy(getState()),
        selectors.selectLimit(getState()),
        selectors.selectOffset(getState()),
      );

      dispatch({
        type: dailyReportListActions.FETCH_SUCCESS,
        payload: {
          rows: response.rows,
          count: response.count,
        },
      });
    } catch (error) {
      Errors.handle(error);

      dispatch({
        type: dailyReportListActions.FETCH_ERROR,
      });
    }
  },

  doFetchMaxBidTableData: () => async (
    dispatch,
    getState,
  ) => {
    try {
      dispatch({
        type: dailyReportListActions.FETCH_MAX_BID_TABLE_DATA_STARTED,
      });

      const rows = tenantSelectors.selectRows(getState());

      const businessObjects = rows.map((row) => {
        const tenantName = row.name;
        const tenantId = row._id;
        return {
            name: tenantName,
            tenantId,
            tenant: row,
            programs: []
        };
      });

      for (const [i, business] of businessObjects.entries()) {
        const currentPrograms = await DailyReportService.getCurrentProgramsWithInfo(business.tenantId);
        businessObjects[i].programs = await currentPrograms;
        
        if (currentPrograms.length) {
          let allLeads = businessObjects[i].programs[0].organicLeads;
          let combinedCost = 0;
          for (const program of businessObjects[i].programs) {
              allLeads += program.totalLeads;
              combinedCost += program.adCost;
          }
          businessObjects[i].allLeads = allLeads;
          businessObjects[i].combinedCost = combinedCost;
          businessObjects[i].dayCount = businessObjects[i].programs[0].dayCounter;
        }
      }

      dispatch({
        type: dailyReportListActions.FETCH_MAX_BID_TABLE_DATA_SUCCESS,
        payload: businessObjects,
      });
    } catch (error) {
      Errors.handle(error);

      dispatch({
        type: dailyReportListActions.FETCH_MAX_BID_TABLE_DATA_ERROR,
      });
    }
  }
};

export default dailyReportListActions;

import ProgramLogService from 'src/modules/programLog/programLogService';
import selectors from 'src/modules/programLog/programLogSelectors';
import Errors from 'src/modules/shared/error/errors';

const prefix = 'PROGRAM_LOG';

const programLogActions = {
  FETCH_STARTED: `${prefix}_FETCH_STARTED`,
  FETCH_SUCCESS: `${prefix}_FETCH_SUCCESS`,
  FETCH_ERROR: `${prefix}_FETCH_ERROR`,

  RESETED: `${prefix}_RESETED`,

  PAGINATION_CHANGED: `${prefix}_PAGINATION_CHANGED`,
  SORTER_CHANGED: `${prefix}_SORTER_CHANGED`,

  doReset: () => async (dispatch) => {
    dispatch({
      type: programLogActions.RESETED,
    });

    dispatch(programLogActions.doFetch());
  },

  doChangePagination: (pagination) => async (
    dispatch,
    getState,
  ) => {
    dispatch({
      type: programLogActions.PAGINATION_CHANGED,
      payload: pagination,
    });

    const filter = selectors.selectFilter(getState());
    const rawFilter = selectors.selectRawFilter(getState());
    dispatch(programLogActions.doFetch(filter, rawFilter, true));
  },

  doChangeSort: (sorter) => async (dispatch, getState) => {
    dispatch({
      type: programLogActions.SORTER_CHANGED,
      payload: sorter,
    });

    const filter = selectors.selectFilter(getState());
    const rawFilter = selectors.selectRawFilter(getState());
    dispatch(programLogActions.doFetch(filter, rawFilter, true));
  },

  doFetch: (filter?, rawFilter?, keepPagination = false) => async (
    dispatch,
    getState,
  ) => {
    try {
      dispatch({
        type: programLogActions.FETCH_STARTED,
        payload: { filter, rawFilter, keepPagination },
      });

      const response = await ProgramLogService.fetch(
        filter,
        selectors.selectOrderBy(getState()),
        // selectors.selectLimit(getState()),
        // selectors.selectOffset(getState()),
      );

      let filteredRows = response.rows;
    
      if (filter && filter.tenantNames && filter.tenantNames.length > 0) {
        filteredRows = filteredRows.filter(row =>
          filter.tenantNames.some(filterName =>
            row.tenantName.toLowerCase().includes(filterName.toLowerCase())
          )
        );
      }

      const totalFilteredCount = filteredRows.length;

      // Apply pagination to the filtered rows
      const currentPage = selectors.selectPagination(getState()).current || 1;
      const pageSize = selectors.selectPagination(getState()).pageSize || 25;
      const paginatedRows = filteredRows.slice(
        (currentPage - 1) * pageSize,
        currentPage * pageSize
      );

      dispatch({
        type: programLogActions.FETCH_SUCCESS,
        payload: {
          rows: paginatedRows,
          count: totalFilteredCount,
        },
      });
    } catch (error) {
      Errors.handle(error);

      dispatch({
        type: programLogActions.FETCH_ERROR,
      });
    }
  },
};

export default programLogActions;

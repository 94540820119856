import list from 'src/modules/userData/list/userDataListReducers';
import form from 'src/modules/userData/form/userDataFormReducers';
import view from 'src/modules/userData/view/userDataViewReducers';
import destroy from 'src/modules/userData/destroy/userDataDestroyReducers';
import importerReducer from 'src/modules/userData/importer/userDataImporterReducers';
import { combineReducers } from 'redux';

export default combineReducers({
  list,
  form,
  view,
  destroy,
  importer: importerReducer,
});

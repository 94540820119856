import { useEffect } from 'react';
import { History } from 'history';

const GA4_MEASUREMENT_ID = 'G-P5M5WL5D70'; 

declare global {
  interface Window {
    gtag: (...args: any[]) => void;
    dataLayer: any[];
  }
}

export const useAnalytics = (history: History) => {
  useEffect(() => {
    // Define gtag function if not already defined
    window.gtag = window.gtag || function(){(window.dataLayer = window.dataLayer || []).push(arguments);}

    // Initialize GA4
    window.gtag('js', new Date());
    window.gtag('config', GA4_MEASUREMENT_ID);

    // Listen for route changes and record page views
    return history.listen((location) => {
      window.gtag('config', GA4_MEASUREMENT_ID, {
        page_path: location.pathname,
        page_title: document.title,
      });
    });
  }, [history]);
};
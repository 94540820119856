import importerActions from 'src/modules/shared/importer/importerActions';
import selectors from 'src/modules/userData/importer/userDataImporterSelectors';
import UserDataService from 'src/modules/userData/userDataService';
import fields from 'src/modules/userData/importer/userDataImporterFields';
import { i18n } from 'src/i18n';

const userDataImporterActions = importerActions(
  'USERDATA_IMPORTER',
  selectors,
  UserDataService.import,
  fields,
  i18n('entities.userData.importer.fileName'),
);

export default userDataImporterActions;
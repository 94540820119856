import importerActions from 'src/modules/shared/importer/importerActions';
import selectors from 'src/modules/ads/importer/adsImporterSelectors';
import AdsService from 'src/modules/ads/adsService';
import fields from 'src/modules/ads/importer/adsImporterFields';
import { i18n } from 'src/i18n';

const adsImporterActions = importerActions(
  'ADS_IMPORTER',
  selectors,
  AdsService.import,
  fields,
  i18n('entities.ads.importer.fileName'),
);

export default adsImporterActions;
import { i18n } from 'src/i18n';
import exporterRenders from 'src/modules/shared/exporter/exporterRenders';

export default [
  {
    name: 'id',
    label: i18n('entities.monthlyReports.fields.id'),
  },
  {
    name: 'start',
    label: i18n('entities.monthlyReports.fields.start'),
  },
  {
    name: 'end',
    label: i18n('entities.monthlyReports.fields.end'),
  },
  {
    name: 'businessId',
    label: i18n('entities.monthlyReports.fields.businessId'),
  },
  {
    name: 'totalPageViews',
    label: i18n('entities.monthlyReports.fields.totalPageViews'),
  },
  {
    name: 'calls',
    label: i18n('entities.monthlyReports.fields.calls'),
  },
  {
    name: 'mapViews',
    label: i18n('entities.monthlyReports.fields.mapViews'),
  },
  {
    name: 'urlClicks',
    label: i18n('entities.monthlyReports.fields.urlClicks'),
  },
  {
    name: 'checkIns',
    label: i18n('entities.monthlyReports.fields.checkIns'),
  },
  {
    name: 'userPhotos',
    label: i18n('entities.monthlyReports.fields.userPhotos'),
  },
  {
    name: 'bookmarks',
    label: i18n('entities.monthlyReports.fields.bookmarks'),
  },
  {
    name: 'desktopCTAClicks',
    label: i18n('entities.monthlyReports.fields.desktopCTAClicks'),
  },
  {
    name: 'mobileCTAClicks',
    label: i18n('entities.monthlyReports.fields.mobileCTAClicks'),
  },
  {
    name: 'messagesToBusiness',
    label: i18n('entities.monthlyReports.fields.messagesToBusiness'),
  },
  {
    name: 'mobilePageViews',
    label: i18n('entities.monthlyReports.fields.mobilePageViews'),
  },
  {
    name: 'desktopSearchAppearances',
    label: i18n('entities.monthlyReports.fields.desktopSearchAppearances'),
  },
  {
    name: 'mobileSearchAppearances',
    label: i18n('entities.monthlyReports.fields.mobileSearchAppearances'),
  },
  {
    name: 'desktopPageViews',
    label: i18n('entities.monthlyReports.fields.desktopPageViews'),
  },
  {
    name: 'trackingCalls',
    label: i18n('entities.monthlyReports.fields.trackingCalls'),
  },
  {
    name: 'dealsSold',
    label: i18n('entities.monthlyReports.fields.dealsSold'),
  },
  {
    name: 'onlineOrders',
    label: i18n('entities.monthlyReports.fields.onlineOrders'),
  },
  {
    name: 'onlineBookings',
    label: i18n('entities.monthlyReports.fields.onlineBookings'),
  },
  {
    name: 'billedImpressions',
    label: i18n('entities.monthlyReports.fields.billedImpressions'),
  },
  {
    name: 'billedClicks',
    label: i18n('entities.monthlyReports.fields.billedClicks'),
  },
  {
    name: 'adCost',
    label: i18n('entities.monthlyReports.fields.adCost'),
  },
  {
    name: 'adDrivenBookmarks',
    label: i18n('entities.monthlyReports.fields.adDrivenBookmarks'),
  },
  {
    name: 'adDrivenCalls',
    label: i18n('entities.monthlyReports.fields.adDrivenCalls'),
  },
  {
    name: 'adDrivenCTAClicks',
    label: i18n('entities.monthlyReports.fields.adDrivenCTAClicks'),
  },
  {
    name: 'adDrivenCheckIns',
    label: i18n('entities.monthlyReports.fields.adDrivenCheckIns'),
  },
  {
    name: 'adDrivenDealsSold',
    label: i18n('entities.monthlyReports.fields.adDrivenDealsSold'),
  },
  {
    name: 'adDrivenMapViews',
    label: i18n('entities.monthlyReports.fields.adDrivenMapViews'),
  },
  {
    name: 'adDrivenMessages',
    label: i18n('entities.monthlyReports.fields.adDrivenMessages'),
  },
  {
    name: 'adDrivenUserPhotos',
    label: i18n('entities.monthlyReports.fields.adDrivenUserPhotos'),
  },
  {
    name: 'adDrivenReservations',
    label: i18n('entities.monthlyReports.fields.adDrivenReservations'),
  },
  {
    name: 'adDrivenUrlClicks',
    label: i18n('entities.monthlyReports.fields.adDrivenUrlClicks'),
  },
  {
    name: 'createdAt',
    label: i18n('entities.monthlyReports.fields.createdAt'),
    render: exporterRenders.datetime(),
  },
  {
    name: 'updatedAt',
    label: i18n('entities.monthlyReports.fields.updatedAt'),
    render: exporterRenders.datetime(),
  },
];

import DailyReportService from 'src/modules/dailyReport/dailyReportService';
import Errors from 'src/modules/shared/error/errors';
import Message from 'src/view/shared/message';
import { getHistory } from 'src/modules/store';
import { i18n } from 'src/i18n';

const prefix = 'DAILYREPORT_FORM';

const dailyReportFormActions = {
  INIT_STARTED: `${prefix}_INIT_STARTED`,
  INIT_SUCCESS: `${prefix}_INIT_SUCCESS`,
  INIT_ERROR: `${prefix}_INIT_ERROR`,

  CREATE_STARTED: `${prefix}_CREATE_STARTED`,
  CREATE_SUCCESS: `${prefix}_CREATE_SUCCESS`,
  CREATE_ERROR: `${prefix}_CREATE_ERROR`,

  UPDATE_STARTED: `${prefix}_UPDATE_STARTED`,
  UPDATE_SUCCESS: `${prefix}_UPDATE_SUCCESS`,
  UPDATE_ERROR: `${prefix}_UPDATE_ERROR`,

  doInit: (id) => async (dispatch) => {
    try {
      dispatch({
        type: dailyReportFormActions.INIT_STARTED,
      });

      let record = {};

      const isEdit = Boolean(id);

      if (isEdit) {
        record = await DailyReportService.find(id);
      }

      dispatch({
        type: dailyReportFormActions.INIT_SUCCESS,
        payload: record,
      });
    } catch (error) {
      Errors.handle(error);

      dispatch({
        type: dailyReportFormActions.INIT_ERROR,
      });

      getHistory().push('/daily-report');
    }
  },

  doCreate: (values) => async (dispatch) => {
    try {
      dispatch({
        type: dailyReportFormActions.CREATE_STARTED,
      });

      await DailyReportService.create(values);

      dispatch({
        type: dailyReportFormActions.CREATE_SUCCESS,
      });

      Message.success(
        i18n('entities.dailyReport.create.success'),
      );

      getHistory().push('/daily-report');
    } catch (error) {
      Errors.handle(error);

      dispatch({
        type: dailyReportFormActions.CREATE_ERROR,
      });
    }
  },

  doUpdate: (id, values) => async (dispatch, getState) => {
    try {
      dispatch({
        type: dailyReportFormActions.UPDATE_STARTED,
      });

      await DailyReportService.update(id, values);

      dispatch({
        type: dailyReportFormActions.UPDATE_SUCCESS,
      });

      Message.success(
        i18n('entities.dailyReport.update.success'),
      );

      getHistory().push('/daily-report');
    } catch (error) {
      Errors.handle(error);

      dispatch({
        type: dailyReportFormActions.UPDATE_ERROR,
      });
    }
  },
};

export default dailyReportFormActions;

import { i18n } from 'src/i18n';
import exporterRenders from 'src/modules/shared/exporter/exporterRenders';

export default [
  {
    name: 'id',
    label: i18n('entities.dailyReport.fields.id'),
  },
  {
    name: 'start',
    label: i18n('entities.dailyReport.fields.start'),
  },
  {
    name: 'end',
    label: i18n('entities.dailyReport.fields.end'),
  },
  {
    name: 'businessId',
    label: i18n('entities.dailyReport.fields.businessId'),
  },
  {
    name: 'date',
    label: i18n('entities.dailyReport.fields.date'),
    render: exporterRenders.datetime(),
  },
  {
    name: 'totalPageViews',
    label: i18n('entities.dailyReport.fields.totalPageViews'),
  },
  {
    name: 'calls',
    label: i18n('entities.dailyReport.fields.calls'),
  },
  {
    name: 'mapViews',
    label: i18n('entities.dailyReport.fields.mapViews'),
  },
  {
    name: 'uRLClicks',
    label: i18n('entities.dailyReport.fields.uRLClicks'),
  },
  {
    name: 'checkIns',
    label: i18n('entities.dailyReport.fields.checkIns'),
  },
  {
    name: 'bookmarks',
    label: i18n('entities.dailyReport.fields.bookmarks'),
  },
  {
    name: 'desktopCTAClicks',
    label: i18n('entities.dailyReport.fields.desktopCTAClicks'),
  },
  {
    name: 'mobileCTAClicks',
    label: i18n('entities.dailyReport.fields.mobileCTAClicks'),
  },
  {
    name: 'messagesToBusiness',
    label: i18n('entities.dailyReport.fields.messagesToBusiness'),
  },
  {
    name: 'desktopSearchAppearances',
    label: i18n('entities.dailyReport.fields.desktopSearchAppearances'),
  },
  {
    name: 'mobileSearchAppearances',
    label: i18n('entities.dailyReport.fields.mobileSearchAppearances'),
  },
  {
    name: 'trackingCalls',
    label: i18n('entities.dailyReport.fields.trackingCalls'),
  },
  {
    name: 'dealsSold',
    label: i18n('entities.dailyReport.fields.dealsSold'),
  },
  {
    name: 'onlineOrders',
    label: i18n('entities.dailyReport.fields.onlineOrders'),
  },
  {
    name: 'onlineBookings',
    label: i18n('entities.dailyReport.fields.onlineBookings'),
  },
  {
    name: 'billedImpressions',
    label: i18n('entities.dailyReport.fields.billedImpressions'),
  },
  {
    name: 'billedClicks',
    label: i18n('entities.dailyReport.fields.billedClicks'),
  },
  {
    name: 'adCost',
    label: i18n('entities.dailyReport.fields.adCost'),
  },
  {
    name: 'adDrivenCalls',
    label: i18n('entities.dailyReport.fields.adDrivenCalls'),
  },
  {
    name: 'adDrivenCTAClicks',
    label: i18n('entities.dailyReport.fields.adDrivenCTAClicks'),
  },
  {
    name: 'adDrivenCheckIns',
    label: i18n('entities.dailyReport.fields.adDrivenCheckIns'),
  },
  {
    name: 'adDrivenDealsSold',
    label: i18n('entities.dailyReport.fields.adDrivenDealsSold'),
  },
  {
    name: 'adDrivenMapViews',
    label: i18n('entities.dailyReport.fields.adDrivenMapViews'),
  },
  {
    name: 'adDrivenMessages',
    label: i18n('entities.dailyReport.fields.adDrivenMessages'),
  },
  {
    name: 'adDrivenUserPhotos',
    label: i18n('entities.dailyReport.fields.adDrivenUserPhotos'),
  },
  {
    name: 'adDrivenReservations',
    label: i18n('entities.dailyReport.fields.adDrivenReservations'),
  },
  {
    name: 'adDrivenUrlClicks',
    label: i18n('entities.dailyReport.fields.adDrivenUrlClicks'),
  },
  {
    name: 'createdAt',
    label: i18n('entities.dailyReport.fields.createdAt'),
    render: exporterRenders.datetime(),
  },
  {
    name: 'updatedAt',
    label: i18n('entities.dailyReport.fields.updatedAt'),
    render: exporterRenders.datetime(),
  },
];

import { i18n } from 'src/i18n';
import exporterRenders from 'src/modules/shared/exporter/exporterRenders';

export default [
  {
    name: 'id',
    label: i18n('entities.ads.fields.id'),
  },
  {
    name: 'programName',
    label: i18n('entities.ads.fields.programName'),
  },
  {
    name: 'startDate',
    label: i18n('entities.ads.fields.startDate'),
  },
  {
    name: 'endDate',
    label: i18n('entities.ads.fields.endDate'),
    render: exporterRenders.datetime(),
  },
  {
    name: 'businessId',
    label: i18n('entities.ads.fields.businessId'),
  },
  {
    name: 'jobId',
    label: i18n('entities.ads.fields.jobId'),
  },
  {
    name: 'budget',
    label: i18n('entities.ads.fields.budget'),
  },
  {
    name: 'currencyCode',
    label: i18n('entities.ads.fields.currencyCode'),
  },
  {
    name: 'isAutobid',
    label: i18n('entities.ads.fields.isAutobid'),
    render: exporterRenders.boolean(),
  },
  {
    name: 'maxBid',
    label: i18n('entities.ads.fields.maxBid'),
  },
  {
    name: 'pacingMethod',
    label: i18n('entities.ads.fields.pacingMethod'),
  },
  {
    name: 'feePeriod',
    label: i18n('entities.ads.fields.feePeriod'),
  },
  {
    name: 'adCategories',
    label: i18n('entities.ads.fields.adCategories'),
  },
  {
    name: 'futureBudgetDate',
    label: i18n('entities.ads.fields.futureBudgetDate'),
  },
  {
    name: 'programId',
    label: i18n('entities.ads.fields.programId'),
  },
  {
    name: 'createdAt',
    label: i18n('entities.ads.fields.createdAt'),
    render: exporterRenders.datetime(),
  },
  {
    name: 'updatedAt',
    label: i18n('entities.ads.fields.updatedAt'),
    render: exporterRenders.datetime(),
  },
];

import list from 'src/modules/monthlyReports/list/monthlyReportsListReducers';
import form from 'src/modules/monthlyReports/form/monthlyReportsFormReducers';
import view from 'src/modules/monthlyReports/view/monthlyReportsViewReducers';
import destroy from 'src/modules/monthlyReports/destroy/monthlyReportsDestroyReducers';
import importerReducer from 'src/modules/monthlyReports/importer/monthlyReportsImporterReducers';
import { combineReducers } from 'redux';

export default combineReducers({
  list,
  form,
  view,
  destroy,
  importer: importerReducer,
});

import list from 'src/modules/dailyReport/list/dailyReportListReducers';
import form from 'src/modules/dailyReport/form/dailyReportFormReducers';
import view from 'src/modules/dailyReport/view/dailyReportViewReducers';
import destroy from 'src/modules/dailyReport/destroy/dailyReportDestroyReducers';
import importerReducer from 'src/modules/dailyReport/importer/dailyReportImporterReducers';
import { combineReducers } from 'redux';

export default combineReducers({
  list,
  form,
  view,
  destroy,
  importer: importerReducer,
});

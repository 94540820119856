import importerActions from 'src/modules/shared/importer/importerActions';
import selectors from 'src/modules/monthlyReports/importer/monthlyReportsImporterSelectors';
import MonthlyReportsService from 'src/modules/monthlyReports/monthlyReportsService';
import fields from 'src/modules/monthlyReports/importer/monthlyReportsImporterFields';
import { i18n } from 'src/i18n';

const monthlyReportsImporterActions = importerActions(
  'MONTHLYREPORTS_IMPORTER',
  selectors,
  MonthlyReportsService.import,
  fields,
  i18n('entities.monthlyReports.importer.fileName'),
);

export default monthlyReportsImporterActions;
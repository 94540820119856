import AdsService from 'src/modules/ads/adsService';
import Errors from 'src/modules/shared/error/errors';
import Message from 'src/view/shared/message';
import { getHistory } from 'src/modules/store';
import { i18n } from 'src/i18n';

const prefix = 'ADS_FORM';

const adsFormActions = {
  INIT_STARTED: `${prefix}_INIT_STARTED`,
  INIT_SUCCESS: `${prefix}_INIT_SUCCESS`,
  INIT_ERROR: `${prefix}_INIT_ERROR`,

  CREATE_STARTED: `${prefix}_CREATE_STARTED`,
  CREATE_SUCCESS: `${prefix}_CREATE_SUCCESS`,
  CREATE_ERROR: `${prefix}_CREATE_ERROR`,

  UPDATE_STARTED: `${prefix}_UPDATE_STARTED`,
  UPDATE_SUCCESS: `${prefix}_UPDATE_SUCCESS`,
  UPDATE_ERROR: `${prefix}_UPDATE_ERROR`,

  doCreate: (values) => async (dispatch) => {
    try {
      dispatch({
        type: adsFormActions.CREATE_STARTED,
      });

      await AdsService.create(values);

      dispatch({
        type: adsFormActions.CREATE_SUCCESS,
      });

      Message.success(
        i18n('entities.ads.create.success'),
      );

      getHistory().push('/ads');
    } catch (error) {
      Errors.handle(error);

      dispatch({
        type: adsFormActions.CREATE_ERROR,
      });
    }
  },

  doUpdate: (id, values) => async (dispatch, getState) => {
    try {
      dispatch({
        type: adsFormActions.UPDATE_STARTED,
      });

      await AdsService.update(id, values);

      dispatch({
        type: adsFormActions.UPDATE_SUCCESS,
      });

      Message.success(
        i18n('entities.ads.update.success'),
      );

      getHistory().push('/ads');
    } catch (error) {
      Errors.handle(error);

      dispatch({
        type: adsFormActions.UPDATE_ERROR,
      });
    }
  },
};

export default adsFormActions;

import importerActions from 'src/modules/shared/importer/importerActions';
import selectors from 'src/modules/dailyReport/importer/dailyReportImporterSelectors';
import DailyReportService from 'src/modules/dailyReport/dailyReportService';
import fields from 'src/modules/dailyReport/importer/dailyReportImporterFields';
import { i18n } from 'src/i18n';

const dailyReportImporterActions = importerActions(
  'DAILYREPORT_IMPORTER',
  selectors,
  DailyReportService.import,
  fields,
  i18n('entities.dailyReport.importer.fileName'),
);

export default dailyReportImporterActions;
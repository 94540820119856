import list from 'src/modules/ads/list/adsListReducers';
import form from 'src/modules/ads/form/adsFormReducers';
import view from 'src/modules/ads/view/adsViewReducers';
import destroy from 'src/modules/ads/destroy/adsDestroyReducers';
import importerReducer from 'src/modules/ads/importer/adsImporterReducers';
import { combineReducers } from 'redux';

export default combineReducers({
  list,
  form,
  view,
  destroy,
  importer: importerReducer,
});

// import AdsService from 'src/modules/ads/adsService';
// import Errors from 'src/modules/shared/error/errors';
// import { getHistory } from 'src/modules/store';

const prefix = 'ADS_VIEW';

const adsViewActions = {
  FIND_STARTED: `${prefix}_FIND_STARTED`,
  FIND_SUCCESS: `${prefix}_FIND_SUCCESS`,
  FIND_ERROR: `${prefix}_FIND_ERROR`,

  // doFind: (id) => async (dispatch) => {
  //   try {
  //     dispatch({
  //       type: adsViewActions.FIND_STARTED,
  //     });

  //     const record = await AdsService.find(id);

  //     dispatch({
  //       type: adsViewActions.FIND_SUCCESS,
  //       payload: record,
  //     });
  //   } catch (error) {
  //     Errors.handle(error);

  //     dispatch({
  //       type: adsViewActions.FIND_ERROR,
  //     });

  //     getHistory().push('/ads');
  //   }
  // },
};

export default adsViewActions;

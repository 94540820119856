import schemas from 'src/modules/shared/yup/yupImporterSchemas';
import { i18n } from 'src/i18n';import moment from 'moment';

export default [
  {
    name: 'start',
    label: i18n('entities.monthlyReports.fields.start'),
    schema: schemas.date(
      i18n('entities.monthlyReports.fields.start'),
      {},
    ),
   render: (value) => value && value instanceof Date ? moment(value).format('YYYY-MM-DD') : value,
  },
  {
    name: 'end',
    label: i18n('entities.monthlyReports.fields.end'),
    schema: schemas.date(
      i18n('entities.monthlyReports.fields.end'),
      {},
    ),
   render: (value) => value && value instanceof Date ? moment(value).format('YYYY-MM-DD') : value,
  },
  {
    name: 'businessId',
    label: i18n('entities.monthlyReports.fields.businessId'),
    schema: schemas.string(
      i18n('entities.monthlyReports.fields.businessId'),
      {},
    ),
  },
  {
    name: 'totalPageViews',
    label: i18n('entities.monthlyReports.fields.totalPageViews'),
    schema: schemas.integer(
      i18n('entities.monthlyReports.fields.totalPageViews'),
      {},
    ),
  },
  {
    name: 'calls',
    label: i18n('entities.monthlyReports.fields.calls'),
    schema: schemas.integer(
      i18n('entities.monthlyReports.fields.calls'),
      {},
    ),
  },
  {
    name: 'mapViews',
    label: i18n('entities.monthlyReports.fields.mapViews'),
    schema: schemas.integer(
      i18n('entities.monthlyReports.fields.mapViews'),
      {},
    ),
  },
  {
    name: 'urlClicks',
    label: i18n('entities.monthlyReports.fields.urlClicks'),
    schema: schemas.integer(
      i18n('entities.monthlyReports.fields.urlClicks'),
      {},
    ),
  },
  {
    name: 'checkIns',
    label: i18n('entities.monthlyReports.fields.checkIns'),
    schema: schemas.integer(
      i18n('entities.monthlyReports.fields.checkIns'),
      {},
    ),
  },
  {
    name: 'userPhotos',
    label: i18n('entities.monthlyReports.fields.userPhotos'),
    schema: schemas.integer(
      i18n('entities.monthlyReports.fields.userPhotos'),
      {},
    ),
  },
  {
    name: 'bookmarks',
    label: i18n('entities.monthlyReports.fields.bookmarks'),
    schema: schemas.integer(
      i18n('entities.monthlyReports.fields.bookmarks'),
      {},
    ),
  },
  {
    name: 'desktopCTAClicks',
    label: i18n('entities.monthlyReports.fields.desktopCTAClicks'),
    schema: schemas.integer(
      i18n('entities.monthlyReports.fields.desktopCTAClicks'),
      {},
    ),
  },
  {
    name: 'mobileCTAClicks',
    label: i18n('entities.monthlyReports.fields.mobileCTAClicks'),
    schema: schemas.integer(
      i18n('entities.monthlyReports.fields.mobileCTAClicks'),
      {},
    ),
  },
  {
    name: 'messagesToBusiness',
    label: i18n('entities.monthlyReports.fields.messagesToBusiness'),
    schema: schemas.integer(
      i18n('entities.monthlyReports.fields.messagesToBusiness'),
      {},
    ),
  },
  {
    name: 'mobilePageViews',
    label: i18n('entities.monthlyReports.fields.mobilePageViews'),
    schema: schemas.integer(
      i18n('entities.monthlyReports.fields.mobilePageViews'),
      {},
    ),
  },
  {
    name: 'desktopSearchAppearances',
    label: i18n('entities.monthlyReports.fields.desktopSearchAppearances'),
    schema: schemas.integer(
      i18n('entities.monthlyReports.fields.desktopSearchAppearances'),
      {},
    ),
  },
  {
    name: 'mobileSearchAppearances',
    label: i18n('entities.monthlyReports.fields.mobileSearchAppearances'),
    schema: schemas.integer(
      i18n('entities.monthlyReports.fields.mobileSearchAppearances'),
      {},
    ),
  },
  {
    name: 'desktopPageViews',
    label: i18n('entities.monthlyReports.fields.desktopPageViews'),
    schema: schemas.integer(
      i18n('entities.monthlyReports.fields.desktopPageViews'),
      {},
    ),
  },
  {
    name: 'trackingCalls',
    label: i18n('entities.monthlyReports.fields.trackingCalls'),
    schema: schemas.integer(
      i18n('entities.monthlyReports.fields.trackingCalls'),
      {},
    ),
  },
  {
    name: 'dealsSold',
    label: i18n('entities.monthlyReports.fields.dealsSold'),
    schema: schemas.integer(
      i18n('entities.monthlyReports.fields.dealsSold'),
      {},
    ),
  },
  {
    name: 'onlineOrders',
    label: i18n('entities.monthlyReports.fields.onlineOrders'),
    schema: schemas.integer(
      i18n('entities.monthlyReports.fields.onlineOrders'),
      {},
    ),
  },
  {
    name: 'onlineBookings',
    label: i18n('entities.monthlyReports.fields.onlineBookings'),
    schema: schemas.integer(
      i18n('entities.monthlyReports.fields.onlineBookings'),
      {},
    ),
  },
  {
    name: 'billedImpressions',
    label: i18n('entities.monthlyReports.fields.billedImpressions'),
    schema: schemas.integer(
      i18n('entities.monthlyReports.fields.billedImpressions'),
      {},
    ),
  },
  {
    name: 'billedClicks',
    label: i18n('entities.monthlyReports.fields.billedClicks'),
    schema: schemas.integer(
      i18n('entities.monthlyReports.fields.billedClicks'),
      {},
    ),
  },
  {
    name: 'adCost',
    label: i18n('entities.monthlyReports.fields.adCost'),
    schema: schemas.integer(
      i18n('entities.monthlyReports.fields.adCost'),
      {},
    ),
  },
  {
    name: 'adDrivenBookmarks',
    label: i18n('entities.monthlyReports.fields.adDrivenBookmarks'),
    schema: schemas.integer(
      i18n('entities.monthlyReports.fields.adDrivenBookmarks'),
      {},
    ),
  },
  {
    name: 'adDrivenCalls',
    label: i18n('entities.monthlyReports.fields.adDrivenCalls'),
    schema: schemas.integer(
      i18n('entities.monthlyReports.fields.adDrivenCalls'),
      {},
    ),
  },
  {
    name: 'adDrivenCTAClicks',
    label: i18n('entities.monthlyReports.fields.adDrivenCTAClicks'),
    schema: schemas.integer(
      i18n('entities.monthlyReports.fields.adDrivenCTAClicks'),
      {},
    ),
  },
  {
    name: 'adDrivenCheckIns',
    label: i18n('entities.monthlyReports.fields.adDrivenCheckIns'),
    schema: schemas.integer(
      i18n('entities.monthlyReports.fields.adDrivenCheckIns'),
      {},
    ),
  },
  {
    name: 'adDrivenDealsSold',
    label: i18n('entities.monthlyReports.fields.adDrivenDealsSold'),
    schema: schemas.integer(
      i18n('entities.monthlyReports.fields.adDrivenDealsSold'),
      {},
    ),
  },
  {
    name: 'adDrivenMapViews',
    label: i18n('entities.monthlyReports.fields.adDrivenMapViews'),
    schema: schemas.integer(
      i18n('entities.monthlyReports.fields.adDrivenMapViews'),
      {},
    ),
  },
  {
    name: 'adDrivenMessages',
    label: i18n('entities.monthlyReports.fields.adDrivenMessages'),
    schema: schemas.integer(
      i18n('entities.monthlyReports.fields.adDrivenMessages'),
      {},
    ),
  },
  {
    name: 'adDrivenUserPhotos',
    label: i18n('entities.monthlyReports.fields.adDrivenUserPhotos'),
    schema: schemas.integer(
      i18n('entities.monthlyReports.fields.adDrivenUserPhotos'),
      {},
    ),
  },
  {
    name: 'adDrivenReservations',
    label: i18n('entities.monthlyReports.fields.adDrivenReservations'),
    schema: schemas.integer(
      i18n('entities.monthlyReports.fields.adDrivenReservations'),
      {},
    ),
  },
  {
    name: 'adDrivenUrlClicks',
    label: i18n('entities.monthlyReports.fields.adDrivenUrlClicks'),
    schema: schemas.integer(
      i18n('entities.monthlyReports.fields.adDrivenUrlClicks'),
      {},
    ),
  },
];
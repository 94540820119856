import authAxios from 'src/modules/shared/axios/authAxios';
import AuthCurrentTenant from 'src/modules/auth/authCurrentTenant';

export default class DailyReportService {
  static async update(id, data) {
    const body = {
      id,
      data,
    };

    const tenantId = AuthCurrentTenant.get();

    const response = await authAxios.put(
      `/tenant/${tenantId}/daily-report/${id}`,
      body,
    );

    return response.data;
  }

  static async destroyAll(ids) {
    const params = {
      ids,
    };

    const tenantId = AuthCurrentTenant.get();

    const response = await authAxios.delete(
      `/tenant/${tenantId}/daily-report`,
      {
        params,
      },
    );

    return response.data;
  }

  static async create(data) {
    const body = {
      data,
    };

    const tenantId = AuthCurrentTenant.get();

    const response = await authAxios.post(
      `/tenant/${tenantId}/daily-report`,
      body,
    );

    return response.data;
  }

  static async createJob(data) {
    const body = {
      data,
    };

    const tenantId = AuthCurrentTenant.get();
    
    const response = await authAxios.post(
      `/tenant/${tenantId}/daily-report/job`,
      body,
      );
      
      return response;
    }
    
  static async fetch(jobId) {
    const tenantId = AuthCurrentTenant.get();
      
    const response = await authAxios.get(
      `/tenant/${tenantId}/daily-report/fetch/${jobId}`,
    );

    return response;
  }

  static async fetchHistoric() {
    const tenantId = AuthCurrentTenant.get();
      
    authAxios.post(
      `/tenant/${tenantId}/daily-report/historic`,
    );
  }

  static async import(values, importHash) {
    const body = {
      data: values,
      importHash,
    };

    const tenantId = AuthCurrentTenant.get();

    const response = await authAxios.post(
      `/tenant/${tenantId}/daily-report/import`,
      body,
    );

    return response.data;
  }

  static async getReportDataInRange(startDateString, endDateString) {
    const body = {
      startDateString,
      endDateString,
    };

    const tenantId = AuthCurrentTenant.get();

    const response = await authAxios.post(
      `/tenant/${tenantId}/daily-report/reports-range`,
      body,
    );

    return response.data;
  }

  static async getCompanyDataInRange(startDateString, endDateString, tenantInfo) {
    const body = {
      startDateString,
      endDateString,
      tenantInfo
    };

    const response = await authAxios.post(
      `/daily-report/company-reports-range`,
      body,
    );

    return response.data;
  }

  static async getCurrentMonthReportData() {
    const date = new Date();
    // Target time is 2:30pm , when daily reports have finished processing
    const targetTime = new Date();
    targetTime.setHours(14, 30, 0, 0);
    // If before 2:30pm use 2 days prior else use one day prior
    if (date < targetTime) {
        date.setDate(date.getDate() - 2);
    } else {
        date.setDate(date.getDate() - 1);
    }

    let mm: any = date.getMonth() + 1;
    mm = (mm < 10 ? '0' : '') + mm;
    let day: any = date.getDate();
    const dd = (day < 10 ? '0' : '') + day;
    const startDateString = `${date.getFullYear()}-${mm}-01`;
    const endDateString = `${date.getFullYear()}-${mm}-${dd}`;

    const body = {
      startDateString,
      endDateString,
    };

    const tenantId = AuthCurrentTenant.get();

    const response = await authAxios.post(
      `/tenant/${tenantId}/daily-report/reports-range`,
      body,
    );

    return response.data;
  }

  static async getCurrentProgramsWithInfo(tenantId) {
    const body = {
      tenantId
    };

    const response = await authAxios.post(
      `/daily-report/current-programs-and-info`,
      body,
    );

    return response.data;
  }

  static async writeBusinessData() {
    const response = await authAxios.get(
      `/daily-report/business-data/write`,
    );

    return response.data;
  }

  static async readBusinessData() {
    const response = await authAxios.get(
      `/daily-report/business-data/read`
    )

    return response.data;
  }

  static async find(id) {
    const tenantId = AuthCurrentTenant.get();

    const response = await authAxios.get(
      `/tenant/${tenantId}/daily-report/${id}`,
    );

    return response.data;
  }

  static async findProgramInfoEntry(programId, date) {
    const body = {
      programId,
      date,
    };

    const response = await authAxios.post(
      '/daily-report/program-info/find',
      body,
    );

    return response.data;
  }

  static async findLastProgramInfoEntry(programId) {
    const body = {
      programId,
    };

    const response = await authAxios.post(
      '/daily-report/program-info/find-last',
      body,
    );

    return response.data;
  }

  static async findAllLastProgramInfoEntries() {
    const response = await authAxios.get( '/daily-report/program-info/find-last');

    return response.data;
  }

  static async list(filter, orderBy, limit, offset) {
    const params = {
      filter,
      orderBy,
      limit,
      offset,
    };

    const tenantId = AuthCurrentTenant.get();

    const response = await authAxios.get(
      `/tenant/${tenantId}/daily-report`,
      {
        params,
      },
    );

    return response.data;
  }

  static async listAutocomplete(query, limit) {
    const params = {
      query,
      limit,
    };

    const tenantId = AuthCurrentTenant.get();

    const response = await authAxios.get(
      `/tenant/${tenantId}/daily-report/autocomplete`,
      {
        params,
      },
    );

    return response.data;
  }
}

import listActions from 'src/modules/dailyReport/list/dailyReportListActions';
import DailyReportService from 'src/modules/dailyReport/dailyReportService';
import Errors from 'src/modules/shared/error/errors';
import { i18n } from 'src/i18n';
import { getHistory } from 'src/modules/store';
import Message from 'src/view/shared/message';

const prefix = 'DAILYREPORT_DESTROY';

const dailyReportDestroyActions = {
  DESTROY_STARTED: `${prefix}_DESTROY_STARTED`,
  DESTROY_SUCCESS: `${prefix}_DESTROY_SUCCESS`,
  DESTROY_ERROR: `${prefix}_DESTROY_ERROR`,

  DESTROY_ALL_STARTED: `${prefix}_DESTROY_ALL_STARTED`,
  DESTROY_ALL_SUCCESS: `${prefix}_DESTROY_ALL_SUCCESS`,
  DESTROY_ALL_ERROR: `${prefix}_DESTROY_ALL_ERROR`,

  doDestroy: (id) => async (dispatch) => {
    try {
      dispatch({
        type: dailyReportDestroyActions.DESTROY_STARTED,
      });

      await DailyReportService.destroyAll([id]);

      dispatch({
        type: dailyReportDestroyActions.DESTROY_SUCCESS,
      });

      Message.success(
        i18n('entities.dailyReport.destroy.success'),
      );

      dispatch(listActions.doFetchCurrentFilter());

      getHistory().push('/daily-report');
    } catch (error) {
      Errors.handle(error);

      dispatch(listActions.doFetchCurrentFilter());

      dispatch({
        type: dailyReportDestroyActions.DESTROY_ERROR,
      });
    }
  },

  doDestroyAll: (ids) => async (dispatch) => {
    try {
      dispatch({
        type: dailyReportDestroyActions.DESTROY_ALL_STARTED,
      });

      await DailyReportService.destroyAll(ids);

      dispatch({
        type: dailyReportDestroyActions.DESTROY_ALL_SUCCESS,
      });

      if (listActions) {
        dispatch(listActions.doClearAllSelected());
        dispatch(listActions.doFetchCurrentFilter());
      }

      Message.success(
        i18n('entities.dailyReport.destroyAll.success'),
      );

      getHistory().push('/daily-report');
    } catch (error) {
      Errors.handle(error);

      dispatch(listActions.doFetchCurrentFilter());

      dispatch({
        type: dailyReportDestroyActions.DESTROY_ALL_ERROR,
      });
    }
  },
};

export default dailyReportDestroyActions;
